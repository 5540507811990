import type { UnwrapRef } from 'vue';

/* eslint-disable no-undef */
export default function useSetState<T>(
  initialValue: T,
): [Ref<UnwrapRef<T>>, (value: UnwrapRef<T>) => void] {
  const state = ref<T>(initialValue);

  const setState = (value: UnwrapRef<T>) => {
    state.value = value;
  };

  return [state, setState];
}
